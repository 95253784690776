<template>
  <v-sheet color="transparent" :style="subCategory ? `border-top : 1px solid black` : '' ">

    <h5 class="d-flex align-center" >
      <v-sheet color="transparent" width="38" >
        <v-btn v-if="(expandable || courses.length > 0) && category.value !== 'all' "  @click="expandAction" icon :color="wsACCENT">
          <v-icon>
            mdi-menu-{{ expand ? 'down' : 'right' }}
          </v-icon>
        </v-btn>
      </v-sheet>

      <ws-check-box
          v-model="selected"
          :semiSelected="semiSelectCheck"
          @input="selectAction"
          :color="wsACCENT"
          :small="false" />
      {{ category.text  }}
    </h5>

    <v-expand-transition>
      <v-sheet :color="subCategory ? wsBACKGROUND : 'transparent'" v-if="expand && courses.length > 0">

        <courseSelectorCourse
            v-for="course in courses"
            @select="selectCourse"
            @schedule="$emit('schedule',$event)"

            @group="selectGroup($event)"

            :course="course"
            :key="course.uuid"
            v-model="result"
        />

      </v-sheet>
    </v-expand-transition>


  </v-sheet>

</template>

<script>
import courseSelectorCourse from "@/components/pages/businessDashboard/UI/courseSelector/courseSelectorCourse";
export default {
  name: "courseSelectorCategory",
  components : {
    courseSelectorCourse
  },
  props : {
    value : {
      type : Array,
      default() { return [] }
    },
    category : {
      type : Object,
      default() { return {}}
    },
    expandable : {
      type : Boolean,
      default : false
    },
    courses : {
      type : Array,
      default() { return [] }
    },
    coursesAll : {
      type : Array,
      default() { return [] }
    },
    subCategory : {
      type : Boolean,
      default : false
    },
    subCategoriesIds : {
      type : Array,
      default() { return [] }
    },
  },
  data() {
    return {
      expand : false,
      result : [],
      selected : false,
    }
  },
  computed : {
    selectorCheck() {
      if (this.result.length === 0 ) {
        return false
      }
      let checkArray = this.result.filter( el => this.totalEntitiesIds.includes(el.uuid) )

      if ( this.totalEntitiesIds.length > 0 ) {
        return checkArray.length === this.totalEntitiesIds.length
      }

      return !!this.result.find(el => el.uuid === this.category.value)
    },
    semiSelectCheck() {
      if (this.result.length === 0 ) {
        return false
      }


      let array = this.result.map(el => el.parent )

      if ( array.includes(this.category.value) && !this.selectorCheck ) {
        return true
      }



      let checkArray = JSON.parse(JSON.stringify(this.subCategoriesIds))

      if ( this.courses.length > 0 ) {
        this.courses.forEach(item => {
          checkArray.push(item.uuid)
          item.enrollment_waves.forEach(wave => {
            checkArray.push(wave.value)
          })
        })
      } else {
        this.subCategoriesIds.forEach(id => {
          this.categoryCourses(id).forEach(item => {
            checkArray.push(item.uuid)
            item.enrollment_waves.forEach(wave => {
              checkArray.push(wave.value)
            })
          })
        })
      }

      let parentCheck = array.filter( el => checkArray.includes(el)  )

      return parentCheck.length > 0 && !this.selectorCheck

    },
    totalEntitiesIds() {
      let result = []

      if ( !this.subCategory ) {
        this.subCategoriesIds.forEach(id => {
          result.push(id)
          this.categoryCourses(id).forEach(course => {
            result.push( course.uuid)
            if ( course.is_online ) {
              course.enrollment_waves.forEach(wave => {
                result.push(wave.value)
                wave.groups.forEach(group => {
                  result.push(group.value)
                })
              })
            } else {
              course.groups.forEach(group => {
                if ( !group.enrollment_wave ) {
                  result.push(group.value)
                }
              })
            }
          })
        })
      }

      this.courses.forEach(course => {
        result.push(course.uuid)
        if ( course.is_online ) {
          course.enrollment_waves.forEach(wave => {
            result.push(wave.value)
            wave.groups.forEach(group => {
              result.push(group.value)
            })
          })
        } else {
          course.groups.forEach(group => {
            if ( !group.enrollment_wave ) {
              result.push(group.value)
            }
          })
        }
      })

      return result
    },
  },
  watch : {
    selectorCheck(value) {
      this.selected = value
    },
    value() {
      if (this.value.length !== this.result.length ) {
        this.result = this.value
      }
    }
  },
  methods : {

    selectCourse($event) {
      $event.parent = this.category.value
      this.$emit('course',$event)
    },
    selectGroup($event) {
      this.$emit('group', $event)
    },

    selectAction($event) {
      this.$emit('select' , $event)
    },
    expandAction() {
      this.expand=!this.expand
      this.$emit('expand') ;
    },
    categoryCourses(uuid) {
      let items = this.coursesAll
      if (uuid && uuid !== 'all') {
        uuid = uuid !== 'unsorted' ? uuid : null
        items = items.filter( el => el.category_id === uuid )
      }
      return items
    },

  },
  mounted() {
    if ( this.value !== this.result ) {
      this.result = this.value
      this.selected = this.selectorCheck
    }
  }
}
</script>

<style scoped>

</style>