<template>
  <h5 class="d-flex align-center"
      :class="[{'pl-9' : child}]"
  >
    <ws-check-box
        v-model="selected"
        @input="selectAction"
        :color="wsACCENT"
        :small="false" />
    {{ group.text  }}
  </h5>

</template>

<script>
export default {
  name: "courseSelectorCourseSchedule",
  props: {
    value : {
      type : Array,
      default() { return [] }
    },
    group : {
      type : Object,
      default() { return {} }
    },
    child : {
      type : Boolean,
      deafult : false
    }
  },
  data() {
    return {
      result : [],
      selected : false,
    }
  },
  computed : {
    selectorCheck() {
      if (this.result.length === 0 ) {
        return false
      }
      return !!this.result.find(el => el.uuid === this.group.value )
    }
  },
  watch : {
    value() {
      if (this.value.length !== this.result.length ) {
        this.result = this.value
      }
    },
    selectorCheck(value) {
      this.selected = value
    },
  },
  methods : {
    selectAction($event) {
      this.$emit('select', { uuid : this.group.value , value : $event  })
    }
  },
  mounted() {
    this.result = this.value
    this.selected = this.selectorCheck
  }
}
</script>

<style scoped>

</style>