<template>
  <div>

    <courseSelectorRootCategory
        v-for="category in rootCourseCategories "

        @rootCategory="rootCategoryAction($event,category)"
        @category="childCategoryAction($event,category)"
        @course="courseAction($event)"
        @schedule="scheduleAction($event)"
        @group="groupAction($event)"

        :category="category"
        :sub-categories="childCourseCategories(category.value)"
        :key="category.value"
        :courses="courses"
        v-model="result"

    />

    <courseSelectorCourse
        v-for="course in categoryCourses('unsorted')"
        @select="courseAction($event)"
        @schedule="scheduleAction($event)"
        @group="groupAction($event)"
        :course="course"
        :key="course.uuid"
        v-model="result"
    />



  </div>

</template>

<script>
import {mapActions} from "vuex";
import courseSelectorCourse from "@/components/pages/businessDashboard/UI/courseSelector/courseSelectorCourse";
import courseSelectorRootCategory
  from "@/components/pages/businessDashboard/UI/courseSelector/courseSelectorRootCategory";
export default {
  name: "courseSelector",
  components : {
    courseSelectorRootCategory,
    courseSelectorCourse
  },
  props : {
    value : {
      type : Array,
      default : () => []
    }
  },
  data() {
    return {
      courses : [],
      categories : [],
      result : []
    }
  },
  computed : {

    rootCourseCategories() {
      if ( this.categories.length === 0 ) {
        return []
      }
      let items = this.categories.filter( el => el.parent === null )
      return items
    },
  },
  watch : {
    result() {
      if ( JSON.stringify(this.value) !== JSON.stringify(this.result) ) {
        this.$emit('input',this.value)
      }
    },
    value() {
      if ( JSON.stringify(this.value) !== JSON.stringify(this.result) ) {
        this.result = this.value
      }
    }
  },
  methods : {
    ...mapActions( 'courses', [ 'GET_COURSE_SELECTOR' ] ),

    rootCategoryAction(value , category , child = false ) {
      this.selectEntity(category.value , 'category' , value)

      if ( !child ) {
        this.childCourseCategories(category.value).forEach(child => {
          this.childCategoryAction( { uuid : child.value , value : value } , category , true )
        })
        this.categoryCourses(category.value).forEach(course => {
          let data = {
            uuid : course.uuid,
            value : value,
            parent : category.value
          }
          this.courseAction(data , true )
        })

      }


    },
    childCategoryAction( $event , category , parent = false , child = false ) {

      this.selectEntity($event.uuid , 'category' , $event.value , category.value)
      if ( !$event.value && !parent ) {

        if (this.result.filter(el => el.parent === category.value ).length === 0 ) {
          this.selectEntity(category.value , 'category' , false)
        }

      }
      if ( $event.value && !parent ) {
        this.selectEntity(category.value , 'category' , true)
      }

      if ( !child ) {
        this.categoryCourses($event.uuid).forEach(course => {
          let data = {
            uuid : course.uuid,
            value : $event.value,
            parent : $event.uuid,
          }
          this.courseAction(data , true)
        })
      }

    },
    courseAction($event , parent = false , child = false ) {
      let course = this.courses.find(el => el.uuid === $event.uuid )
      this.selectEntity($event.uuid , 'course' , $event.value , $event.parent , $event.params)

      if ( course.is_online && course && !child ) {
        course.enrollment_waves.forEach(schedule => {
          let data = {
            uuid : schedule.value,
            value : $event.value,
            parent : course.uuid
          }
          this.scheduleAction(data,true)
        })
      } else if (course && !child) {
        course.groups.forEach(group => {

          let data = {
            uuid : group.value,
            value : $event.value,
            parent : course.uuid
          }
          this.groupAction(data,true)

        })
      }

      if ( parent || !$event.parent  ) {
        return
      }

      let parentCategory = this.categories.find(el => el.value === $event.parent)

      if ( !parentCategory ) {
        return
      }
      let rootParentCategory = !parentCategory.parent
      if ( !rootParentCategory) {
        parentCategory = this.categories.find(el => el.value === parentCategory.parent)
      }

      if ( !$event.value ) {

        if ( this.result.filter(el => el.parent === $event.parent ).length === 0 ) {
          rootParentCategory
              ? this.rootCategoryAction($event.value , parentCategory , true)
              : this.childCategoryAction({ uuid : $event.parent , value : $event.value } , parentCategory , false,true)
        }

      }
      if ( $event.value ) {

        rootParentCategory
            ? this.rootCategoryAction($event.value , parentCategory , true)
            : this.childCategoryAction({ uuid : $event.parent , value : $event.value } , parentCategory , false,true)

      }

    },
    scheduleAction($event, parent = false , child = false) {
      let schedule = null
      let course = this.courses.find(el => el.uuid === $event.parent )
      if ( course ) {
        schedule = course.enrollment_waves.find(el => el.value === $event.uuid)
      }
      this.selectEntity($event.uuid , 'schedule' , $event.value , $event.parent )

      if ( schedule && !child ) {
        schedule.groups.forEach( group => {
          this.selectEntity(group.value,'group',$event.value , $event.uuid )
        })
      }

      if (parent ) {
        return
      }

      let data = {
        uuid : course.uuid,
        value : $event.value,
        parent : course.category_id
      }

      if ( !$event.value ) {
        if (this.result.filter(el => el.parent === course.uuid && el.type === 'schedule' ).length === 0 ) {
          this.courseAction(data,false,true)
        }
      } else {
        this.courseAction(data,false,true)
      }


    },
    groupAction($event, parent = false) {


      let courseEntity = null
      let scheduleRoot = false

      courseEntity = this.courses.find( el=> el.uuid === $event.parent )

      if ( !courseEntity ) {
        this.courses.forEach(course => {
          let scheduleSearch = course.enrollment_waves.find( el=> el.value === $event.parent )
          if ( scheduleSearch  ) {
            courseEntity = course
          }
        })
        scheduleRoot = true
      }


      this.selectEntity($event.uuid , 'group' , $event.value , $event.parent )
      if (parent ) {
        return
      }

      let data = {
        uuid : $event.parent,
        value : $event.value,
        parent : scheduleRoot ? courseEntity.uuid : courseEntity.category_id
      }

      if ( !$event.value ) {
        if (this.result.filter(el => el.parent === $event.parent && el.type === 'group' ).length === 0 ) {
          scheduleRoot
              ? this.scheduleAction(data,false,true)
              : this.courseAction(data,false,true)
        }
      } else {

        scheduleRoot
            ? this.scheduleAction(data,false,true)
            : this.courseAction(data,false,true)

      }

    },


    selectEntity( uuid , type ,  value , parent = null , params = {} ) {
      let entityIndex = this.result.findIndex(el=> el.uuid === uuid)


      if ( value && entityIndex === -1 ) {
        let data = { type : type , uuid : uuid , parent : parent  }

        if ( Object.keys(params).length > 0 ) {
          Object.keys(params).forEach(key => {
            data[key] = params[key]
          })

        }
        this.result.push( data )
      }
      if ( !value && entityIndex !== -1 ) {
        this.result.splice(entityIndex,1)
      }

    },

    childCourseCategories(parentId) {
      return this.categories.filter(el => el.parent === parentId )
    },

    categoryCourses(uuid) {
      let items = this.courses
      if (uuid && uuid !== 'all') {
        uuid = uuid !== 'unsorted' ? uuid : null
        items = items.filter( el => el.category_id === uuid )
      }
      return items
    },

  },
  async mounted() {

    let result = await this.GET_COURSE_SELECTOR()
    if ( !result ) {
      return
    }

    result.categories.forEach(item => {
      item.expand = true
    })

    result.courses.forEach(item => {
      item.expand = false
    })

    this.courses = result.courses
    this.categories = result.categories
    this.result = this.value
  }
}
</script>

<style scoped>

</style>