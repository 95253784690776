<template>
  <v-sheet
      :style="`border-bottom : 1px solid ${wsBACKGROUND}`"
  >

    <courseSelectorCategory
        @select="$emit('rootCategory',$event)"
        @course="$emit('course',$event)"
        @schedule="$emit('schedule',$event)"
        @group="$emit('group',$event)"


        @expand="expand=!expand"
        :category="category"
        :expandable="subCategories.length > 0"
        :courses="categoryCourses(category.value)"
        :coursesAll="courses"
        :subCategoriesIds="subCategoriesIds"
        v-model="result"
    />

    <v-expand-transition>

      <v-sheet :color="expand ? wsLIGHTCARD : 'transparent'" v-if="expand">

        <courseSelectorCategory
            v-for="child in subCategories"

            @select="$emit('category',{uuid : child.value , value : $event})"
            @course="$emit('course',$event)"
            @schedule="$emit('schedule',$event)"
            @group="$emit('group',$event)"

            :category="child"
            :key="child.uuid"
            subCategory
            :courses="categoryCourses(child.value)"
            v-model="result"
        />

      </v-sheet>

    </v-expand-transition>

  </v-sheet>
</template>

<script>

import courseSelectorCategory from "@/components/pages/businessDashboard/UI/courseSelector/courseSelectorCategory";
export default {
  name: "courseSelectorRootCategory",
  components : {
    courseSelectorCategory
  },
  props : {
    value : {
      type : Array,
      default() { return [] }
    },
    category : {
      type : Object,
      default() { return {}}
    },
    subCategories : {
      type : Array,
      default() { return [] }
    },
    courses : {
      type : Array,
      default() { return [] }
    }
  },
  data() {
    return {
      expand : false,
      result : []
    }
  },
  computed : {
    subCategoriesIds() {
      if (this.subCategories.length === 0 ) {
        return []
      }
      return this.subCategories.map(el => el.value )
    }
  },
  watch : {
    value : {
      handler() {
        this.result = this.value
      },
      deep : true
    },

    result : {
      handler() {
        if ( this.value !== this.result ) {
          this.$emit('input',this.result)
        }
      },
      deep : true
    }
  },
  methods : {

    selectGroup($event) {
      this.$emit('group', $event)
    },

    checkDeleteSubCategories() {

      if ( !Object.keys(this.result.categories).includes(this.category.value)) {
        this.subCategories.forEach(subCategory => {
          this.subCategorySelect(subCategory,false)
        })
      }

    },
    subCategorySelect( subCategory, value) {
      if ( !this.result.categories ) {
        this.result.categories = {}
      }

      if ( value ) {
        if ( !this.result.categories[subCategory.value]) {
          this.result.categories[subCategory.value] = { access : true }
        }
        if ( !this.result.categories[subCategory.value].access) {
          this.result.categories[subCategory.value]. access = true
        }

      }
      if ( !value ) {
        if ( this.result.categories[subCategory.value]) {
          delete this.result.categories[subCategory.value]
        }
      }


    },
    //technical
    categoryCourses(uuid) {
      let items = this.courses
      if (uuid && uuid !== 'all') {
        uuid = uuid !== 'unsorted' ? uuid : null
        items = items.filter( el => el.category_id === uuid )
      }
      return items
    },
  },
  mounted() {
    if ( this.value !== this.result ) {
      this.result = this.value
    }
  }

}
</script>

<style scoped>

</style>