<template>
  <v-sheet color="transparent" :style=" `border-top : 3px solid black`">
    <h5 class="d-flex align-center"  >
      <v-sheet color="transparent" width="38" >
        <v-btn
            v-if="courseExpandCondition"
            @click="expand = !expand"
            :color="wsACCENT"
            icon >
          <v-icon>
            mdi-menu-{{ expand ? 'down' : 'right' }}
          </v-icon>
        </v-btn>
      </v-sheet>
      <ws-check-box
          v-model="selected"
          @input="selectCourseAction($event)"
          :color="wsACCENT"
          :semiSelected="semiSelectCheck"
          :small="false" />
      {{ course.name  }}
    </h5>

    <v-expand-transition>
      <v-sheet :color="expand ? wsLIGHTCARD : '' " v-if="expand" class="pl-10">
        <div v-if="course.is_online">
          <course-selector-course-schedule
              v-for="schedule in course.enrollment_waves"
              v-model="result"
              @select="selectScheduleAction($event)"
              @group="selectGroupAction($event , schedule.value)"
              :schedule="schedule"
              :key="schedule.value"

          />
        </div>
        <div v-else>
          <course-selector-course-group
              v-for="group in course.groups"
              v-model="result"
              @select="selectGroupAction($event,course.uuid)"
              :group="group"
              :key="group.value"
          />
        </div>


      </v-sheet>
    </v-expand-transition>


  </v-sheet>
</template>

<script>
import courseSelectorCourseSchedule
  from "@/components/pages/businessDashboard/UI/courseSelector/courseSelectorCourseSchedule";
import courseSelectorCourseGroup
  from "@/components/pages/businessDashboard/UI/courseSelector/courseSelectorCourseGroup";


export default {
  name: "courseSelectorCourse",
  components : {
    courseSelectorCourseSchedule,
    courseSelectorCourseGroup
  },
  props : {
    value : {
      type : Array,
      default() { return [] }
    },
    course : {
      type : Object,
      default() { return {} }
    },
  },
  data() {
    return {
      expand : false,
      result : [],
      selected : false,
    }
  },
  computed : {
    courseExpandCondition() {
      if (this.course.is_online) {
        return this.course.enrollment_waves.length > 0
      } else {
        return this.course.groups.length > 0
      }
    },
    selectorCheck() {
      if (this.result.length === 0 ) {
        return false
      }
      let checkArray = this.result.filter( el => this.totalEntitiesIds.includes(el.uuid) )

      if ( this.totalEntitiesIds.length > 0 ) {
        return checkArray.length === this.totalEntitiesIds.length
      }

      return !!this.result.find(el => el.uuid === this.course.uuid)
    },
    semiSelectCheck() {
      if (this.result.length === 0 ) {
        return false
      }
      let array = this.result.map(el => el.parent )
      let scheduleArray = this.course.enrollment_waves.map(el => el.value)

      let parentCheck = array.filter( el => scheduleArray.includes(el) || el === this.course.uuid )

      return parentCheck.length > 0 && !this.selectorCheck
    },
    totalEntitiesIds() {
      let result = []


        if ( this.course.is_online ) {
          this.course.enrollment_waves.forEach(wave => {
            result.push(wave.value)
            wave.groups.forEach(group => {
              result.push(group.value)
            })
          })
        } else {
          this.course.groups.forEach(group => {
            if ( !group.enrollment_wave ) {
              result.push(group.value)
            }
          })
        }


      return result
    },
  },
  watch : {
    value() {
      if (this.value.length !== this.result.length ) {
        this.result = this.value
      }
    },
    selectorCheck(value) {
      this.selected = value
    },
  },
  methods : {
    selectCourseAction($event) {
      this.$emit('select', { uuid : this.course.uuid , value : $event  })
    },
    selectGroupAction($event , parentId) {
      $event.parent = parentId
      this.$emit('group',$event)
    },
    selectScheduleAction($event) {
      $event.parent = this.course.uuid
      this.$emit('schedule',$event)
    }
  },
  mounted() {
    this.result = this.value
    this.selected = this.selectorCheck
  }
}
</script>

<style scoped>

</style>