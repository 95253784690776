<template>
  <div>
    <h5 class="d-flex align-center" >
      <v-btn
          @click="expand = !expand"
          :color="wsACCENT"
          icon >
        <v-icon>
          mdi-menu-{{ expand ? 'down' : 'right' }}
        </v-icon>
      </v-btn>
      <ws-check-box
          v-model="selected"
          @input="selectAction($event)"
          :semi-selected="semiSelectCheck"
          :color="wsACCENT"
          :small="false" />
      {{ schedule.text  }}
    </h5>

    <v-expand-transition>
      <div v-if="expand">

        <course-selector-course-group
            v-for="group in schedule.groups"
            v-model="result"
            @select="selectGroup($event)"
            :group="group"
            :key="group.value"
            child
        />
      </div>
    </v-expand-transition>

  </div>

</template>

<script>
import courseSelectorCourseGroup
  from "@/components/pages/businessDashboard/UI/courseSelector/courseSelectorCourseGroup";
export default {
  name: "courseSelectorCourseSchedule",
  components : {
    courseSelectorCourseGroup
  },
  props : {
    value : {
      type : Array,
      default() { return [] }
    },
    schedule : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {
      result : [],
      selected : false,
      expand : false
    }
  },
  computed : {
    selectorCheck() {
      if (this.result.length === 0 ) {
        return false
      }
      let checkArray = this.result.filter( el => this.totalEntitiesIds.includes(el.uuid) )

      if ( this.totalEntitiesIds.length > 0 ) {
        return checkArray.length === this.totalEntitiesIds.length
      }

      return !!this.result.find(el => el.uuid === this.schedule.value)
    },
    semiSelectCheck() {
      if (this.result.length === 0 ) {
        return false
      }
      let array = this.result.map(el => el.parent )
      return array.includes(this.schedule.value) && !this.selectorCheck

    },
    totalEntitiesIds() {
      let result = []

      this.schedule.groups.forEach(group => {
        result.push(group.value)
      })

      return result
    },
  },
  watch : {
    value() {
      if (this.value.length !== this.result.length ) {
        this.result = this.value
      }
    },
    selectorCheck(value) {
      this.selected = value
    },
  },
  methods : {
    selectAction($event) {
      this.$emit('select', { uuid : this.schedule.value , value : $event  })
    },
    selectGroup($event) {
      $event.parent = this.schedule.value
      this.$emit('group',$event)
    }
  },
  mounted() {
    this.result = this.value
    this.selected = this.selectorCheck
  }
}
</script>

<style scoped>

</style>